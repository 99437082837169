import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import useStoryblok from '../lib/storyblok'
import { render, NODE_CODEBLOCK } from 'storyblok-rich-text-react-renderer'

import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function BlogEntry({ pageContext, location }) {
  let story = pageContext.story
  story = useStoryblok(story, location)

  return (
    <Layout>
      <Seo
        title={`Blog | ${ story.content.title }`}
        description={ story.content.intro }
        image={`https:${story.content.image}`}
      />
      <div className="grid lg:grid-cols-2 grid-rows-1 place-self-center pb-28">
        <div className="py-28">
          <p className="hero-greeting text-2xl">{ story.content.category }</p>
          <h1 className="header-gradetion hero-headline blog-headline text-5xl font-black text-center pt-3 pb-10">
            { story.content.title }
          </h1>
          <h5>{ story.content.date.slice(-16, -6) }</h5>
          <p className="text-gray-500">{ story.content.intro }</p>
        </div>
        <img
          src={`https://${ story.content.image }`}
          alt={ story.content.image }
          className="hero-img"
        />
      </div>
      <div>
        {
          render(story.content.long_text, {
            nodeResolvers: {
              [NODE_CODEBLOCK]: (children, story) => {
                const language = story.class.slice(9)
                  return (
                      <pre>
                        <code { ...story }>
                          <SyntaxHighlighter language={ language } style={ tomorrow }>
                            { children }
                          </SyntaxHighlighter>
                        </code>
                      </pre>
                  )
                }
              }
            }
          )
        }
      </div>
    </Layout>
  )
}